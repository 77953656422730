<template>
    <div class="aplayz_modal">
        <homeagreeinfo-modal v-if="isOpenAgree" :isen="isen" :infotext="infoText" @close-modal="onCloseModal">
            <template v-if="isen" #homeagreeinfoheadertitle>
                Notice on the Collection and Use of Personal Information (Required)
            </template>

            <template v-if="isen" #homeagreeinfotext1>
                APLAYZ Inc. collects and uses personal information for the purposes outlined below, and we are committed to
                handling your personal information securely.
            </template>

            <template v-if="isen" #strongtext1>
                Collected Information:
            </template>

            <template v-if="isen" #strongtext2>
                Purpose of Collection and Use:
            </template>

            <template v-if="isen" #strongtext3>
                Retention and Use Period:
            </template>

            <template v-if="isen" #homegreeinfotext2sub1="{text3}">
                {{ text3 }}
            </template>

            <template #homegreeinfotext3="{text4}">
                {{ text4 }}
            </template>
        </homeagreeinfo-modal>
        <div class="aplayz_nologin_modal" @click="onClickModal">
            <div class="nologin_modal_header">
                <h1 class="nologin_modal_header_title" v-if="!isComplete">
                    <slot name="headertitle">
                        문의
                    </slot>
                </h1>
                <img
                    class="modal_close_btn cursor"
                    @click="$emit('close-modal')"
                    src="/media/img/close_black.png"
                    alt="close_black"
                />
            </div>
            <div class="nologin_modal_body" v-if="!isComplete">
                <ul class="w_100 nologin_modal_tab">
                    <li class="nologin_modal_tab_item">
                        <button
                            class="nologin_modal_tab_btn"
                            @click="selectTab('tab1')"
                            :class="{ active: isActiveTab === 'tab1' }"
                        >
                            <slot name="modaltabbtn1">
                                제휴 문의
                            </slot>
                        </button>
                    </li>
                    <li class="nologin_modal_tab_item">
                        <button
                            class="nologin_modal_tab_btn"
                            @click="selectTab('tab2')"
                            :class="{ active: isActiveTab === 'tab2' }"
                        >
                            <slot name="modaltabbtn2">
                                1:1 문의
                            </slot>
                        </button>
                    </li>
                </ul>
                <ul class="nologin_modal_form flex_column">
                    <li class="nologin_modal_form_item">
                        <input
                            class="nologin_modal_input"
                            v-model="inquiryData.partnerNm"
                            :placeholder="nameplaceholder"
                            type="text"
                        />
                    </li>
                    <li class="nologin_modal_form_item" v-if="isActiveTab === 'tab1'">
                        <input
                            class="nologin_modal_input"
                            v-model="inquiryData.partnerCompany"
                            :placeholder="companyplaceholder"
                            type="text"
                        />
                    </li>
                    <li class="nologin_modal_form_item">
                        <input
                            class="nologin_modal_input"
                            v-model="inquiryData.partnerEmail"
                            :placeholder="emailplaceholder"
                            type="text"
                        />
                    </li>
                    <li class="nologin_modal_form_item">
                        <input
                            class="nologin_modal_input"
                            v-model="inquiryData.partnerPhone"
                            :placeholder="phoneplaceholder"
                            type="number"
                        />
                    </li>
                    <li class="nologin_modal_form_item" v-if="isActiveTab === 'tab2'">
                        <input
                            class="nologin_modal_input"
                            v-model="inquiryData.partnerTitle"
                            :placeholder="titleplaceholder"
                            type="text"
                        />
                    </li>
                    <li class="nologin_modal_form_item last">
                        <div class="nologin_modal_textareawrap flex_column">
                            <textarea
                                class="nologin_modal_textarea"
                                id="noLoginInquiry"
                                @input="inputEvent"
                                :placeholder="contentplaceholder"
                            ></textarea>
                            <p class="nologin_modal_totalcount">
                                <span class="nologin_modal_count">{{ textCount }}</span
                                >/{{ contentsmax }}
                            </p>
                        </div>
                    </li>
                </ul>
                <ul class="dp_flex align_center nologin_agree_wrap">
                    <li class="h_100per">
                        <img
                            @click="isAgreeChecked = !isAgreeChecked"
                            class="nologin_check_img cursor"
                            :src="
                                isAgreeChecked ? '/media/images/home/check_circle_checked.png' : '/media/images/home/check_circle.png'
                            "
                            alt="check_circle"
                        />
                    </li>
                    <li class="nologin_check_text_wrap" :class="{ en: isen }">
                        <p class="nologin_check_text">
                            <span class="nologin_check_text cursor" @click="isAgreeChecked = !isAgreeChecked">
                                <slot name="checktext">
                                    개인정보 수집 및 이용에 동의합니다
                                </slot>
                            </span>
                            <span class="nologin_check_agreemodal cursor" :class="{ en: isen }" @click="isOpenAgree = true">
                                <slot name="checkagreemodal">
                                    내용확인
                                </slot>
                            </span>
                        </p>
                    </li>
                </ul>
                <button class="nologin_btn" :disabled="btnDisabled" @click="sendInquiry">
                    <slot name="contactbtn">
                        문의하기
                    </slot>
                </button>
            </div>
            <div class="nologin_modal_body_complete" :class="{ en: isen }" v-else>
                <figure class="nologin_check_complete_wrap">
                    <img class="nologin_check_complete" src="/media/images/home/task_alt.png" alt="task_alt.png" />
                </figure>

                <p class="nologin_complete_text">
                    <slot name="completetext">
                        문의가 접수되었습니다. <br />
                        빠른 시일 내에 답변드리겠습니다.
                    </slot>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { createPartnership } from '@/service/api/menuApi';
import HomeAgreeInfoModal from '@/components/home/HomeAgreeInfoModal.vue';
export default {
  name: 'HomeModal',
  props: {
    isen: {
      type: Boolean,
      required: false,
      default: false
    },
    nameplaceholder: {
      type: String,
      required: false,
      default: '이름'
    },
    companyplaceholder: { type: String, required: false, default: '소속(회사명)' },
    emailplaceholder: { type: String, required: false, default: '이메일주소' },
    phoneplaceholder: { type: String, required: false, default: '연락처 (‘-’제외)' },
    titleplaceholder: { type: String, required: false, default: '문의제목' },
    contentplaceholder: { type: String, required: false, default: '문의내용' }
  },
  emits: ['close-modal'],
  components: {
    'homeagreeinfo-modal': HomeAgreeInfoModal
  },
  data () {
    return {
      isActiveTab: 'tab1',
      inquiryText: '',
      isAgreeChecked: false,
      inquiryData: {
        partnerCompany: '',
        partnerTitle: '',
        partnerNm: '',
        partnerEmail: '',
        partnerContent: '',
        partnerPhone: ''
      },
      isComplete: false,
      infoText: '제휴 문의',
      isOpenAgree: false,
      contentsmax: 1000
    };
  },

  mounted() {},

  methods: {
    onCloseModal() {
      this.isOpenAgree = false;
    },
    onClickModal() {
      if (this.isComplete) {
        this.$emit('close-modal');
      }
    },
    inputEvent($event) {
      this.inquiryData.partnerContent = $event.target.value;
      if (this.inquiryData.partnerContent.length > this.contentsmax) {
        this.inquiryData.partnerContent = this.inquiryData.partnerContent.slice(0, this.contentsmax);
        $event.target.value = this.inquiryData.partnerContent;
      }
    },
    selectTab(tabvalue) {
      if (tabvalue === 'tab1') {
        this.infoText = '제휴 문의';
      } else {
        this.infoText = '1:1 문의';
      }
      if (this.isActiveTab !== tabvalue) {
        const textArea = document.querySelector('#noLoginInquiry');
        textArea.value = '';
        this.isActiveTab = tabvalue;
        this.isAgreeChecked = false;

        Object.keys(this.inquiryData).forEach(item => {
          this.inquiryData[item] = '';
        });
      }
    },
    async sendInquiry() {
      const sendObj = {};
      Object.keys(this.inquiryData).forEach(item => {
        sendObj[item] = this.inquiryData[item];
      });
      if (this.isActiveTab === 'tab1') {
        sendObj.partnerCategory = 'P';
        delete sendObj.partnerTitle;
      } else {
        sendObj.partnerCategory = 'N';
      }
      try {
        const { data } = await createPartnership(sendObj);
        const { resultCd } = data;
        if (resultCd === '0000') {
          this.isComplete = true;
          // this.$emit('close-modal');
        }
      } catch (error) {
        console.error('sendInquiry error : ', error);
      }
    }
  },
  computed: {
    textCount() {
      return this.inquiryData.partnerContent.length;
    },
    btnDisabled() {
      const { partnerNm, partnerCompany, partnerTitle, partnerEmail, partnerPhone, partnerContent } = this.inquiryData;
      if (this.isActiveTab === 'tab1') {
        if (
          partnerNm.length > 0 &&
          partnerCompany.length > 0 &&
          partnerEmail.length > 0 &&
          partnerPhone.length > 0 &&
          partnerContent.length > 0 &&
          this.isAgreeChecked
        ) {
          return false;
        }
      } else {
        if (
          partnerNm.length > 0 &&
          partnerEmail.length > 0 &&
          partnerPhone.length > 0 &&
          partnerTitle.length > 0 &&
          partnerContent.length > 0 &&
          this.isAgreeChecked
        ) {
          return false;
        }
      }
      return true;
    }
  }
};
</script>
<style scoped src="@/assets/css/common/modal.css"></style>
<style scoped src="@/assets/css/home/homemodal.css"></style>
