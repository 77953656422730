<template>
    <div class="aplayz_modal">
        <div class="aplayz_homeagreeinfo_modal" :class="{ en: isen }">
            <div class="aplayz_homeagreeinfo_header">
                <h1 class="aplayz_homeagreeinfo_header_title">
                    <slot name="homeagreeinfoheadertitle">
                        개인 정보 수집 및 이용에 대한 안내(필수)
                    </slot>
                </h1>
                <img
                    class="modal_close_btn cursor"
                    src="/media/img/close_black.png"
                    @click="$emit('close-modal')"
                    alt="close_black"
                />
            </div>
            <ul class="aplayz_homeagreeinfo_body flex_column">
                <li>
                    <p class="aplayz_homeagreeinfo_text1">
                        <slot name="homeagreeinfotext1">
                            (주)어플레이즈는 아래의 목적으로 개인정보를 수집 및 이용하며, <br class="pc_br" />
                            회원의 개인정보를 안전하게 취급하는데 최선을 다합니다.
                        </slot>
                    </p>
                </li>
                <li class="aplayz_homeagreeinfo_text2_wrap">
                    <ul class="flex_column">
                        <li class="aplayz_homeagreeinfo_text2">
                            <strong class="aplayz_homeagreeinfo_text2_strong">
                                <slot name="strongtext1">
                                    수집 항목 :
                                </slot> </strong
                            >&nbsp;{{ text1 }}
                        </li>
                        <li class="aplayz_homeagreeinfo_text2">
                            <strong class="aplayz_homeagreeinfo_text2_strong">
                                <slot name="strongtext2">
                                    수집 및 이용 목적 :
                                </slot>
                            </strong>
                            &nbsp;{{ text2 }}
                        </li>
                        <li class="aplayz_homeagreeinfo_text2">
                            <strong class="aplayz_homeagreeinfo_text2_strong">
                                <slot name="strongtext3">
                                    보유 및 이용기간 :
                                </slot>
                            </strong>

                            <slot name="homegreeinfotext2sub1" :text3="text3">
                                &nbsp;{{ text3 }}
                                서비스를 위해 검토 완료 후
                                <br class="pc_br" />
                                3개월 간 보관하며, 이후 지체없이 파기
                            </slot>
                        </li>
                    </ul>
                </li>
                <li></li>
            </ul>
            <p class="aplayz_homeagreeinfo_text3">
                <slot name="homegreeinfotext3" :text4="text4"> 미동의 시 {{ text4 }}가 불가합니다. </slot>
            </p>
        </div>
    </div>
</template>
<script>
export default {
  name: 'HomeAgreeInfoModal',
  props: {
    isen: {
      type: Boolean,
      required: false,
      default: false
    },
    infotext: {
      type: String,
      required: false,
      default: '제휴 문의'
    }
  },
  emits: ['close-modal'],
  computed: {
    text1 () {
      if (this.infotext === '제휴 문의') {
        if (this.isen) {
          return 'Name, Company/Organization, Email, Contact Number, Inquiry Details';
        }
        return '이름, 소속(회사명), 이메일, 연락처, 문의 내용';
      }
      if (this.isen) {
        return 'Name, Email, Contact Number, Inquiry Subject, Inquiry Details';
      }
      return '이름, 이메일, 연락처, 문의 제목, 문의 내용';
    },
    text2 () {
      if (this.infotext === '제휴 문의') {
        if (this.isen) {
          return 'To verify contact information for partnership proposals and to respond to inquiries';
        }
        return '제휴 제안에 따른 연락처 정보 확인, 문의사항 대응';
      }
      if (this.isen) {
        return 'To verify contact information and respond to inquiries.';
      }
      return '1:1 문의에 따른 연락처 정보 확인, 문의사항 대응';
    },
    text3 () {
      if (this.infotext === '제휴 문의') {
        if (this.isen) {
          return `Information will be retained for 3 months after the review of the partnership proposal consultation service, after which it will be promptly deleted. If you do not agree, you will not be able to submit a partnership inquiry.`;
        }
        return '제휴 제안 사항 상담';
      }
      if (this.isen) {
        return `Information will be retained for three months after the inquiry has been reviewed and will be destroyed thereafter
without delay. Please note that you cannot submit a 1:1 inquiry if you do not agree to the collection and use of your
personal information.
`;
      }
      return '1:1 문의 상담';
    },
    text4 () {
      if (this.infotext === '제휴 문의') {
        if (this.isen) {
          return `If you do not agree, you will not be able to submit a partnership inquiry.`;
        }

        return `미동의 시 ${this.infotext}가 불가합니다.`;
      }
      if (this.isen) {
        return `if you do not agree to the collection and use of your personal information.`;
      }
      return `미동의 시 ${this.infotext}가 불가합니다.`;
    }
  }
};
</script>
<style scoped src="@/assets/css/common/modal.css"></style>
<style scoped src="@/assets/css/home/homeagreeinfomodal.css"></style>
